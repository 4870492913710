import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { SiteBuilderModule } from '../sharedComponents';
import { allowScroll, preventScroll } from '../../../../helpers';

import { StandardDisplay, ScreenDisplay, FullDisplay } from './displayStyles';
import {
  isPrivateVideo,
  parseStreamlyUrl,
} from '../../../../utils/streamlyVideos';
import {
  VideoModuleDisplayOption,
  VideoModuleDisplayStyles,
  VideoModuleSources,
  VideoModuleType,
} from './types';
import { sourceSettings } from './constants';

export interface VideoModuleProps {
  section: VideoModuleType;
}

export default function VideoModule({ section }: VideoModuleProps) {
  const {
    title,
    titleSize,
    text,
    backgroundColour = 'WHITE',
    link,
    videoId,
    videoSource,
    posterImage: { path: poster } = {},
    videoPosition,
    videoDisplayStyle,
    videoDisplayOption,
    videoWidth,
    videoUrl,
    textColour,
    linkPosition,
  } = section;
  const style = backgroundColour;
  const textStyle = textColour;
  const { accessType, thumbnailUrl, sourceUrl } = parseStreamlyUrl(videoUrl);
  const [modalOpened, setModalOpened] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const history = useHistory();
  const { search, hash } = useLocation();
  const themeConfig = useContext(ThemeContext);
  const siteContent = useSelector(({ siteContent: { data } }) => data);
  const standardVideoWidth = videoWidth === 'STANDARD';

  const {
    visualStyles: vStyles,
    displayStyles,
    textStyles,
  } = themeConfig.siteBuilderModules.videoModule;
  const dStyles = displayStyles[videoDisplayStyle];

  const buildThumbnail = () => {
    if (poster) return poster;
    if (thumbnailUrl && videoSource === VideoModuleSources.STREAMLY_VIDEO) {
      return thumbnailUrl;
    }
    if (videoDisplayStyle !== VideoModuleDisplayStyles.SCREEN_WIDTH) {
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    }
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  };

  const toggleModal = () => {
    // Play the video instead of preview image
    if (videoDisplayOption === VideoModuleDisplayOption.PLAY_IN_PLACE) {
      setVideoPlay(true);
      return;
    }

    // Open new window that contain the video iframe
    if (videoDisplayOption === VideoModuleDisplayOption.NEW_WINDOW) {
      let fulScreenUrl;

      // Streamly doesn't support fullscreen mode, so need o redirect on the video page
      if (videoSource === VideoModuleSources.STREAMLY_VIDEO) {
        fulScreenUrl = sourceUrl;
      } else {
        fulScreenUrl = `${sourceSettings[videoSource].path}${videoId}`;
      }

      window.open(fulScreenUrl, '_blank');
      return;
    }

    // Only for Streamly videos
    if (isPrivateVideo(accessType)) {
      window.open(sourceUrl, '_blank');
      return;
    }

    // Default behaviour. videoDisplayOption === VideoModuleDisplayOption.OVERLAY
    if (modalOpened) {
      allowScroll();
      history.push(`${search}#`);
    } else {
      preventScroll();
      history.push(`${search}#video-${videoId}`);
    }

    setModalOpened((prev) => !prev);
  };

  useEffect(() => {
    const siteContentSectionByType = siteContent?.sections?.find(
      (siteSection: { type: string }) => siteSection.type === section.type,
    );

    hash === '#video' &&
      siteContentSectionByType?.videoId === videoId &&
      setModalOpened(true);
    hash === `#video-${videoId}` && setModalOpened(true);
    // eslint-disable-next-line
  }, []);

  return (
    <SiteBuilderModule
      section={section}
      moduleTheme={{
        vStyles,
        dStyles,
        style,
        textStyle,
        textStyles,
        videoPosition,
      }}
    >
      {videoDisplayStyle === VideoModuleDisplayStyles.STANDARD && (
        <StandardDisplay
          videoId={videoId}
          videoSource={videoSource}
          videoPosition={videoPosition}
          poster={poster}
          title={title}
          titleSize={titleSize}
          text={text}
          link={link}
          streamlyUrl={sourceUrl}
          thumbnail={buildThumbnail()}
          modalOpened={modalOpened}
          toggleModal={toggleModal}
          standardVideoWidth={standardVideoWidth}
          videoPlay={videoPlay}
          linkPosition={linkPosition}
        />
      )}
      {videoDisplayStyle === VideoModuleDisplayStyles.FULL_WIDTH && (
        <FullDisplay
          id={videoId}
          source={videoSource}
          thumbnail={buildThumbnail()}
          poster={poster}
          title={title}
          titleSize={titleSize}
          modalOpened={modalOpened}
          toggleModal={toggleModal}
          streamlyUrl={sourceUrl}
        />
      )}
      {videoDisplayStyle === VideoModuleDisplayStyles.SCREEN_WIDTH && (
        <ScreenDisplay
          id={videoId}
          source={videoSource}
          thumbnail={buildThumbnail()}
          poster={poster}
          videoUrl={videoUrl}
        />
      )}
    </SiteBuilderModule>
  );
}
