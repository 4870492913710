import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Thumbnail, VideoPlayer } from '../../innerComponents';

import { ThumbnailInner } from './index.style';
import {
  isPrivateVideo,
  parseStreamlyUrl,
} from '../../../../../../utils/streamlyVideos';
import { VideoModuleSources } from '../../types';

export const ScreenDisplay = ({ id, source, thumbnail, poster, videoUrl }) => {
  const [videoDisplayed, setVideoDisplayed] = useState(false);
  const { accessType, sourceUrl } = parseStreamlyUrl(videoUrl);

  const onClickHandler = () => {
    if (
      source === VideoModuleSources.STREAMLY_VIDEO &&
      isPrivateVideo(accessType)
    ) {
      window.open(sourceUrl, '_blank');
      return;
    }

    setVideoDisplayed(true);
  };

  return (
    <Thumbnail
      path={thumbnail}
      imgix={!!poster}
      onClick={onClickHandler}
      testId="video-thumbnail-screen"
    >
      {videoDisplayed ? (
        <VideoPlayer source={source} id={id} streamlyUrl={sourceUrl} />
      ) : (
        <ThumbnailInner />
      )}
    </Thumbnail>
  );
};

ScreenDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.oneOf(Object.values(VideoModuleSources)).isRequired,
  thumbnail: PropTypes.string.isRequired,
  poster: PropTypes.string,
  videoUrl: PropTypes.string,
};
