import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Wrapper = styled.div`
  :before {
    content: '';
    display: block;
  }
  iframe,
  .wistia_embed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.videoPlayer,
    )};
`;
