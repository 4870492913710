import React from 'react';
import PropTypes from 'prop-types';

import {
  YoutubeVimeoEmbed,
  WistiaEmbed,
  StreamlyEmbed,
} from './innerComponents';
import { Wrapper } from './index.style';
import { VideoModuleSources } from '../../types';

export const VideoPlayer = ({
  source,
  id,
  autoplay,
  controls,
  title,
  streamlyUrl,
}) => (
  <Wrapper>
    {(source === VideoModuleSources.YOUTUBE ||
      source === VideoModuleSources.VIMEO) && (
      <YoutubeVimeoEmbed
        source={source}
        id={id}
        title={title}
        autoplay={autoplay}
        controls={controls}
      />
    )}
    {source === VideoModuleSources.WISTIA && (
      <WistiaEmbed
        id={id}
        autoPlay={autoplay}
        controlsVisibleOnLoad={controls}
      />
    )}
    {source === VideoModuleSources.STREAMLY_VIDEO && (
      <StreamlyEmbed title={title} streamlyUrl={streamlyUrl} />
    )}
  </Wrapper>
);

VideoPlayer.propTypes = {
  source: PropTypes.oneOf(Object.values(VideoModuleSources)).isRequired,
  id: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  title: PropTypes.string,
  streamlyUrl: PropTypes.string,
};

VideoPlayer.defaultProps = {
  autoplay: true,
  controls: true,
};
