import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import {
  StyledBackgroundImage,
  StyledImageTag,
} from '../../../sharedComponents';
import playIcon from '../../../../../../assets/images/play-button.png';

import { Wrapper, Content } from './index.style';

export const Thumbnail = ({ path, imgix, onClick, children, testId }) => {
  const themeConfig = useContext(ThemeContext);
  const { videoPosition, dStyles } = themeConfig.module || {};
  const displayStyles = dStyles.elements.thumbnail;
  const bgImageStyles = videoPosition
    ? dStyles[videoPosition]?.elements?.bgImage
    : {};

  return (
    <Wrapper>
      <StyledBackgroundImage
        path={path}
        imgix={imgix}
        dStyles={bgImageStyles}
        testId={testId}
      >
        <StyledImageTag
          imageConfig={{ path: playIcon }}
          imgix={false}
          onClick={onClick}
          displayStyles={displayStyles}
          testId="video-play-icon"
        />
        <Content>{children}</Content>
      </StyledBackgroundImage>
    </Wrapper>
  );
};

Thumbnail.propTypes = {
  path: PropTypes.string.isRequired,
  imgix: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  testId: PropTypes.string,
};
