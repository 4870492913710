import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles[theme.module.style])}

  > div {
    background-size: cover;
    background-origin: unset;
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.thumbnail,
      )};

    h2 {
      ${({ theme }) =>
        renderStyledElementStyles(theme, theme.module.vStyles.elements.heading)}
    }
  }
`;

export const Content = styled.div`
  position: relative;
`;
