import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Container, CloseText, CloseBtn } from './index.style';

export const VideoModal = ({ onClose, close, children }) => (
  <Wrapper onClick={onClose} data-testid="video-modal">
    <Container>
      {close?.type === 'button' ? (
        <CloseBtn data-testid="video-modal-close-btn">{close.text}</CloseBtn>
      ) : (
        <CloseText data-testid="video-modal-close-btn">{close.text}</CloseText>
      )}
      {children}
    </Container>
  </Wrapper>
);

VideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  close: PropTypes.shape({
    type: PropTypes.oneOf(['button']),
    text: PropTypes.string,
  }),
  children: PropTypes.node,
};

VideoModal.defaultProps = {
  close: {
    text: '×',
  },
};
