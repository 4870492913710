import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { defaultOptions, sourceSettings } from '../../../../constants';
import { VideoModuleSources } from '../../../../types';

export const YoutubeVimeoEmbed = ({ source, id, title, ...options }) => {
  const params = qs.stringify(
    {
      ...defaultOptions,
      ...options,
    },
    { addQueryPrefix: true },
  );
  const settings = sourceSettings[source];

  return (
    <iframe
      src={`${settings.path}${id}${params}`}
      title={title}
      allowFullScreen
      allow={settings.allow}
      frameBorder="0"
      data-testid={`${source}-embed`}
    />
  );
};

YoutubeVimeoEmbed.propTypes = {
  source: PropTypes.oneOf([
    VideoModuleSources.YOUTUBE,
    VideoModuleSources.VIMEO,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
};
