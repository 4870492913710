import styled from 'styled-components';
import { renderStyledElementStyles, devices } from '../../../../helpers';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  :before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0b0b0b;
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.container)}
`;

export const CloseText = styled.div`
  padding-right: 6px;
  font-size: 28px;
  color: #ffffff;
  text-align: right;
  opacity: 0.5;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;;
  top: 70px;
  right: 20px;
  z-index: 3;
  padding: 10px 18px 8px;
  font-family: "Barlow Condensed Extra Bold", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  transition: border 0.2s, box-shadow 0.2s, color 0.2s, background-color 0.2s;
  cursor: pointer;
  :hover {
    color: rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
    border-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 10px 22px 0 rgb(0 0 0 / 40%);
  }
  @media (min-width: ${devices.breakpoints.tablet}) {
    top: 80px;
    font-size: 25px;
  }
}
`;
