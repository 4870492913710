import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { scriptId } from './constants';
import { VideoModuleSources } from '../../../../types';

export const WistiaEmbed = ({ id, ...options }) => {
  useEffect(() => {
    window._wq = window._wq ?? [];
    window._wq.push({
      id,
      options: {
        playerColor: '#000000',
        ...options,
      },
    });
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      script.async = true;
      script.id = scriptId;

      document.body.appendChild(script);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`wistia_embed wistia_async_${id} videoFoam=true`}
      onClick={(e) => e.stopPropagation()}
      data-testid={`${VideoModuleSources.WISTIA}-embed`}
    />
  );
};

WistiaEmbed.propTypes = {
  id: PropTypes.string.isRequired,
};
