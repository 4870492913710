import { SiteModule, SiteModuleCTA, UploadedImage } from '../../../../types';

export enum VideoModuleSources {
  YOUTUBE = 'YOUTUBE',
  VIMEO = 'VIMEO',
  STREAMLY_VIDEO = 'STREAMLY_VIDEO',
  WISTIA = 'WISTIA',
}

export enum VideoModulePositions {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum VideoModuleDisplayStyles {
  SCREEN_WIDTH = 'SCREEN_WIDTH',
  FULL_WIDTH = 'FULL_WIDTH',
  STANDARD = 'STANDARD',
}

export enum VideoModuleWidth {
  STANDARD = 'STANDARD',
  PAGE = 'PAGE',
}

export enum VideoModuleDisplayOption {
  OVERLAY = 'OVERLAY',
  PLAY_IN_PLACE = 'PLAY_IN_PLACE',
  NEW_WINDOW = 'NEW_WINDOW',
}

export enum VideoModuleStyles {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  ACCENT = 'ACCENT',
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  GREY = 'GREY',
}

export enum VideoTextStyles {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  ACCENT = 'ACCENT',
  DEFAULT = 'DEFAULT',
  INVERTED = 'INVERTED',
  GREY = 'GREY',
}

export enum VideoLinkPosition {
  INLINE = 'INLINE',
  BELOW = 'BELOW',
}

export interface VideoModuleType extends SiteModule {
  title: string;
  titleSize: string;
  text: string;
  link: SiteModuleCTA;
  posterImage?: UploadedImage;
  videoId: string;
  streamlyUrl: string;
  videoPosition: VideoModulePositions;
  videoWidth: VideoModuleWidth;
  videoSource: VideoModuleSources;
  videoDisplayOption: VideoModuleDisplayOption;
  videoUrl: string;
  linkPosition: VideoLinkPosition;
  backgroundColour: VideoModuleStyles;
  textColour: VideoModuleStyles;
  videoDisplayStyle: VideoModuleDisplayStyles;
}
